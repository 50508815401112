Date.prototype.toXML = function () {
    return (this.toXMLDate() + "T" + this.toXMLTime());
}

// For xml date;
Date.prototype.toXMLDate = function () {
    return this.getFullYear() + "-" + (((this.getMonth() + 1) < 10) ? "0" : "") + (this.getMonth() + 1) + "-" + ((this.getDate() < 10) ? "0" : "") + this.getDate();
}

// For xml time;
Date.prototype.toXMLTime = function () {
    return this.timeNow();
}

// For todays date;
Date.prototype.today = function () {
    return ((this.getDate() < 10) ? "0" : "") + this.getDate() + "/" + (((this.getMonth() + 1) < 10) ? "0" : "") + (this.getMonth() + 1) + "/" + this.getFullYear();
}

// For the time now
Date.prototype.timeNow = function () {
    return ((this.getHours() < 10) ? "0" : "") + this.getHours() + ":" + ((this.getMinutes() < 10) ? "0" : "") + this.getMinutes();
}

// For the time now
Date.prototype.timeNowSeconds = function () {
    return ((this.getHours() < 10) ? "0" : "") + this.getHours() + ":" + ((this.getMinutes() < 10) ? "0" : "") + this.getMinutes() + ":" + ((this.getSeconds() < 10) ? "0" : "") + this.getSeconds();
}

String.prototype.toTitleCase = function () {
    return this.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
}

String.prototype.endsWith = function (str) {
    return (this.match(str + "$") == str)
}

String.prototype.startsWith = function (str) {
    return (this.match("^" + str) == str)
}

String.prototype.formatEx = function (tokens) {
    var str = this;
    if (!tokens) return str;
    if (!Array.isArray(tokens)) return str.replace(new RegExp('\\{0\\}', 'g'), tokens);
    for (var i = 0; i < tokens.length; i++) {
        str = str.replace(new RegExp('\\{' + i + '\\}', 'g'), tokens[i]);
    }
    return str;
}

String.prototype.trim = function () {
    return (this.replace(/^[\s\xA0]+/, "").replace(/[\s\xA0]+$/, ""))
}

String.prototype.replaceAll = function (search, replacement) {
    return this.split(search).join(replacement);
};

Object.defineProperties(Date, {
    minValue: { value: -8640000000000000 }, // A number, not a date
    maxValue: { value: 8640000000000000 }
});

