// For xml date+time;
//Url
function addTrailingDirSlash(url) {
    if (url == "") { return "" }
    url = url.replace(/\/\/+/gi, "/").toLowerCase();
    if (url.charAt(0) == '/') { url = url.substring(1) }
    if (url == "" || url == "/") { return "" }
    var urlParts = url.split("/");
    if (urlParts[urlParts.length - 1].indexOf(".") !== -1) { return url }
    return (url + "/").replace(/\/\/+/gi, "/");
}