if (!SIDAction) { var SIDAction = {}; }

//Click+ (Result Types)
//Ctrl        : Short Default Action(Input -> .Input.TextBox("sid",) or Get -> .Find("sid")
//Alt         : Short Get -> .Find("sid")
//Shift       : SID only -> "sid"
//Ctrl + Shift: Long Default Action(Input -> Driver.Input.TextBox("sid",) or Get -> Driver.Fin("sid")
//Alt + Shift : Long Get -> Driver.Get("sid")

SIDAction.getKey = function (codeType, SID) {

    var codeFunctions;
    var preventDefault = false;
    switch (codeType) {

        //Standard And Standard Ex Commands =============================================================================================================
        case 'TextBox':
        case 'TextBoxEx':
        case 'UrlTextBoxEx':
            codeFunctions = ['.Input.TextBox("{0}",)', '.Find("{0}")', 'Driver'];
            break;

        case 'RadioButtonList':
            codeFunctions = ['.Input.RadioButton("{0}",)', '.Find("{0}")', 'Driver'];
            break;

        case 'ListBox':
            codeFunctions = ['.Input.ListBox("{0}",)', '.Find("{0}")', 'Driver'];
            break;

        case 'DropDownList':
            codeFunctions = ['.Input.Dropdown("{0}",)', '.Find("{0}")', 'Driver'];
            break;

        case 'DateDropList':
            codeFunctions = ['.Input.DateDropList("{0}",)', '.Find("{0}")', 'Driver'];
            break;

        case 'CheckBoxList':
            codeFunctions = ['.Input.CheckboxList("{0}",)', '.Find("{0}")', 'Driver'];
            break;

        case 'CheckBox':
        case 'CheckBoxEx':
            codeFunctions = ['.Input.Checkbox("{0}",)', '.Find("{0}")', 'Driver'];
            break;

        case 'FileUpload':
        case 'FileUploadEx':
            codeFunctions = ['.Input.FileUpload("{0}",)', '.Find("{0}")', 'Driver'];
            break;

        case 'RadioButton':
            codeFunctions = ['.Find("{0}").Click', '.Find("{0}")', 'Driver'];
            break;

        case 'Label':
        case 'LabelEx':
            codeFunctions = ['.Find("{0}").Text', '.Find("{0}")', 'Driver'];
            break;

        case 'PinButtonEx':
            codeFunctions = ['.Find("{0}").Click', '.Find("{0}")', 'Driver'];
            break;

        case 'AnchorEx':
            codeFunctions = ['.Find("{0}").Click', '.Find("{0}")', 'Driver'];
            break;

        case 'DateDropDownListEx':
            codeFunctions = ['.Input.DateDropDownList("{0}",)', '.Find("{0}")', 'Driver'];
            break;



        //Rad And Rad Ex Commands =============================================================================================================
        //case'AccessibleRadEditor' : 
        case 'RadCalendar':
            codeFunctions = ['.Input.Rad.DateDropDownList("{0}",)', '.Find("{0}")', 'Driver'];
            break;

        //case'RadCheckBox' :
        //case'RadCheckBoxList' : 
        case 'RadComboBox':
            codeFunctions = ['.Input.Rad.ComboBox("{0}",)', '.Find("{0}")', 'Driver'];
            break;

        case 'RadDatePicker':
            codeFunctions = ['.Input.Rad.DatePicker("{0}",)', '.Find("{0}")', 'Driver'];
            break;

        case 'RadDateTimePicker':
            codeFunctions = ['.Input.Rad.DateTimePicker("{0}",)', '.Find("{0}")', 'Driver'];
            break;

        case 'TimePicker':
            codeFunctions = ['.Input.Rad.TimePicker("{0}",)', '.Find("{0}")', 'Driver'];
            break;

        case 'RadDropDownList':
            codeFunctions = ['.Input.Rad.DropDownList_ByValue("{0}",)', '.Find("{0}")', 'Driver'];
            break;

        case 'RadMonthYearPicker':
            codeFunctions = ['.Input.Rad.MonthYearPicker("{0}",)', '.Find("{0}")', 'Driver'];
            break;

        case 'RadEditor':
            codeFunctions = ['.Input.Rad.Editor("{0}",)', '.Find("{0}")', 'Driver'];
            break;

        //case'RadLabel' : 
        case 'RadListBox':
            codeFunctions = ['.Input.Rad.Dropdown_ByValue("{0}",)', '.Find("{0}")', 'Driver'];
            break;

        //case'RadMaskedTextBox' : 
        //case'RadNumericTextBox' : 
        //case'RadRadioButton' : 
        //case'RadRadioButtonList' : 
        //case'RadSlider' : 
        //case'RadTextBox' : 
        //case'RadToggleButton' :
        //case'RadUpload' : 
        //case'RadColorPicker' :

        case 'DateRadComboBoxEx':
            codeFunctions = ['.Input.Rad.DateComboBox("{0}",)', '.Find("{0}")', 'Driver'];
            break;

        case 'DateRadDropDownListEx':
            codeFunctions = ['.Input.Rad.DateDropDownList("{0}",)', '.Find("{0}")', 'Driver'];
            break;



        //Button Commands =============================================================================================================
        case 'Button':
        case 'HyperLink':
        case 'ImageButton':
        case 'LinkButton':
        case 'ButtonEx':
        case 'HyperLinkEx':
        case 'ImageButtonEx':
        case 'LinkButtonEx':
        case 'RadButtonEx':
        case 'RadButton':
        case 'RadImageButton':
        case 'RadLinkButton':
            codeFunctions = ['.Find("{0}").Click', '.Find("{0}")', 'Driver'];
            preventDefault = true;
            break;



        //Misc And Default Commands =============================================================================================================
        default:
            codeFunctions = ['{0}', '{0}', ''];
            break;

    }
    SIDAction.processKey(codeFunctions, SID, preventDefault)
}

SIDAction.processKey = function (codeFunctions, SID, preventDefault) {
    if (event.button != 2) return false;
    var selectedNetCode;
    if (event.shiftKey) selectedNetCode = '{0}';
    if (event.ctrlKey) selectedNetCode = codeFunctions[0];
    if (event.altKey) selectedNetCode = codeFunctions[1];
    if (event.ctrlKey && event.shiftKey) selectedNetCode = codeFunctions[2] + codeFunctions[0];
    if (event.altKey && event.shiftKey) selectedNetCode = codeFunctions[2] + codeFunctions[1];
    if (!selectedNetCode) return true;
    selectedNetCode = selectedNetCode.formatEx(SID);
    SIDAction.copyFnToClipboard(selectedNetCode);
    if (preventDefault) { event.preventDefault(); }
    return !preventDefault;
}

SIDAction.copyFnToClipboard = function (functionText) {
    navigator.clipboard.writeText(functionText);
    if (!window.Notification) return;
    if (Notification.permission !== 'granted') Notification.requestPermission();
    var notification = new Notification('Copied Function To Clipboard', { body: functionText, });
    setTimeout(() => { notification.close() }, 2500)
};

SIDAction.addClickEvent = function (targetId, fn) {
    var target = document.getElementById(targetId);
    if (!target) return;
    target.addEventListener('mousedown', fn);
    SIDAction.addContextMenuEvent();
}

SIDAction.addContextMenuEvent = function () {
    if (SIDAction.addContextMenuEventAdded) return;
    document.addEventListener('contextmenu', function (event) { SIDAction.processContextMenuKey(); }, true);
    SIDAction.addContextMenuEventAdded = true;
}

SIDAction.processContextMenuKey = function () {
    var preventDefault = false;
    if (event.shiftKey) preventDefault = true;
    if (event.ctrlKey) preventDefault = true;
    if (event.altKey) preventDefault = true;
    if (event.ctrlKey && event.shiftKey) preventDefault = true;
    if (event.altKey && event.shiftKey) preventDefault = true;
    if (!preventDefault) return true;
    event.preventDefault();
    return false;

}